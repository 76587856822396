<template>
    <div class="card-contents">
        <h2>Score-tekst beheer</h2>
        <table class="table" v-if="scoreText">
            <tbody>
                <tr>
                    <td>Id</td>
                    <td>{{scoreText.id}}</td>
                </tr>
                <tr>
                    <td>Naam</td>
                    <td>{{scoreText.score_group}}</td>
                </tr>
                <tr>
                    <td>Score</td>
                    <td>{{scoreText.score}}</td>
                </tr>
                <tr v-if="canEdit">
                    <td>Van %</td>
                    <td>{{scoreText.from_percentage}}</td>
                </tr>
                <tr v-if="canEdit">
                    <td>Tot %</td>
                    <td>{{scoreText.to_percentage}}</td>
                </tr>
               <tr>
                    <td>Sectie</td>
                    <td>{{scoreText.section}}</td>
                </tr>
               <tr>
                    <td>Tekst</td>
                    <td>
                        <div v-if="!editMode" v-html="scoreText.text"></div>
                        <textarea-edit
                            :text="scoreText.text"
                            :recordId="scoreText.id"
                            :valueChanged="textChanged"
                            v-if="editMode"
                            @textChanged="handleTextChange"
                        ></textarea-edit>
                    </td>
                </tr>
               <tr>
                    <td>Tekst assessor</td>
                    <td>
                        <div v-if="!editMode" v-html="scoreText.text_assessor"></div>
                        <textarea-edit
                            :text="scoreText.text_assessor"
                            :recordId="scoreText.id"
                            :valueChanged="assessorTextChanged"
                            v-if="editMode"
                            @textChanged="handleAssesorTextChange"
                        ></textarea-edit>
                    </td>
                </tr>
            </tbody>
        </table>
        <edit-mode v-if="canEdit" @editModeChanged="changeEditMode" :checked="editMode"></edit-mode>
    </div>
</template>

<script>
    export default {
        name: "ScoreText",
        data() {
            return {
                scoreText: null,
                errors: [],
                editMode: false,
                assessorTextChanged: false,
                textChanged: false,
                canEdit: false
            }
        },

        mounted() {
            this.getScoreText();
        },

        props: ['textId'],

        methods: {
            handleAssesorTextChange: function(changedText) {
                if (this.doChangeTest('text_assessor', changedText)) {
                    this.assessorTextChanged = false;
                }
            },

            handleTextChange: function(changedText) {
                if (this.doChangeTest('text', changedText)) {
                    this.textChanged = false;
                }
            },

            doChangeTest(fieldName, changedText) {
                const data = {[fieldName]: changedText};
                axios.patch('/api/scoreText/' + this.textId, data)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.scoreText = response.data.data;
                            return true;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
                return false;
            },

            changeEditMode: function (newEditMode) {
                this.editMode = newEditMode;
            },

            getScoreText() {
                axios.get('/api/scoreText/' + this.textId)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.scoreText = response.data.data;
                            this.canEdit = response.data.canEdit;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    }
</script>

<style scoped>

</style>
