<template>
    <div class="dropdown-edit-component">
        <select name="dropdown-button" v-model="buttonValue" ref="input_element" @change="valuePicked">
            <option value=""></option>
            <option v-for="option in (options)" :value="option">{{option}}</option>
        </select>

        <div v-if="valueChanged">
            <span class="righttop-icon checkicon" @click="saveChanges"></span>
            <span class="righttop-icon delete-icon" @click="cancelChanges"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "dropdown-edit",
    props: ['baseValue', 'options', 'competenceId'],
    data() {
        return {
            editText: '',
            buttonValue: null
        };
    },
    mounted() {
        this.editText = this.text;
        this.buttonValue = this.baseValue;
    },
    computed: {
        valueChanged: function (){
            return this.baseValue !== this.buttonValue;
        }
    },
    methods: {
        valuePicked: function() {
            this.buttonValue = this.$refs.input_element.value;
        },
        saveChanges: function() {
            this.$emit('typeChanged', this.$refs.input_element.value, this.competenceId);
        },
        cancelChanges: function() {
            this.buttonValue = this.baseValue;
        }
    }
}
</script>

<style scoped>
    .checkicon{
        background-image: url('~bootstrap-icons/icons/check-square.svg');
    }
    .delete-icon{
        background-image: url('~bootstrap-icons/icons/x-square-fill.svg');
    }
</style>
