<script>
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    name: "BarChart",
    components: {
        Bar
    },
    props: ['chartData'],
    data() {
        return {
            chartOptions: {
                responsive: true,
            }
        }
    }
}

</script>

<template>
    <Bar id="my-chart-id"
         :options="chartOptions"
         :data="chartData" />
</template>

<style scoped>

</style>
