<template>
    <div class="text-edit-component">
        <input name="edit-text" type="text" v-model="editText" ref="input_element"/>
        <span class="righttop-icon checkicon" @click="saveChanges"></span>
        <span class="righttop-icon delete-icon" @click="cancelChanges"></span>
    </div>
</template>

<script>
export default {
    name: "text-edit",
    props: ['text'],
    data() {
        return {editText: ''};
    },
    mounted() {
        this.editText = this.text;
        this.$refs.input_element.focus();
    },
    methods: {
        saveChanges: function() {
            this.$emit('valueChanged', this.$refs.input_element.value);
        },
        cancelChanges: function() {
            this.$emit('editCancelled');
        }
    }
}
</script>

<style scoped>
    .checkicon{
        background-image: url('~bootstrap-icons/icons/check-square.svg');
    }
    .delete-icon{
        background-image: url('~bootstrap-icons/icons/x-square-fill.svg');
    }
</style>
