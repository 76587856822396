<template>
    <div class="card-contents">
        <h2>Extra vragen beheer</h2>
        <table class="table">
            <thead>
                <th>Id</th>
                <th>Vraag</th>
                <th>Type</th>
                <th>Verplicht</th>
            </thead>
            <tbody>
                <tr
                    :class="{selectable: canEdit}"
                    v-for="question in questions" :key="question.id"
                    @click="editQuestion(question)"
                >
                    <td>{{question.id}}</td>
                    <td>{{question.question}}</td>
                    <td>{{question.type}}</td>
                    <td>{{(question.mandatory) ? 'Ja' : 'Nee'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "Questions",
        data() {
            return {
                questions: [],
                errors: [],
                canEdit: false,
            }
        },

        mounted() {
            this.getQuestions();
        },

        props: ['baseUrl', 'moduleId'],

        methods: {
            feedbackIndication(module) {
                if (module.feedback == 1 && module.pivot.feedback_type !== 'none') {
                    return module.pivot.feedback_type;
                }
                return 'Nee';
            },
            getQuestions() {
                axios.get('/api/modules/'+this.moduleId+'/polls')
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.questions = response.data.data;
                            this.canEdit = response.data.canEdit;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            editModule(module){
                location.href=this.baseUrl+'/dashboard/modules/'+module.id
            }
        }
    }
</script>

<style scoped>
    .selectable:hover td{
        background-color: #7f9b90;
        color: white;
    }
</style>
