<template>
    <div>
        <table class="score-table full-width" v-if="loaded">
            <tr>
                <td class="label">Teamnaam:</td>
                <td>{{ team.name }}</td>
            </tr>
            <tr>
                <td class="label">Aantal vragen:</td>
                <td>{{ scores.total_scores.count }}</td>
            </tr>
            <tr>
                <td class="label">Absolute score:</td>
                <td>{{ scores.total_scores.total }}</td>
            </tr>
            <tr>
                <td class="label">Percentuele score:</td>
                <td>{{ scores.total_scores.percentage }} %</td>
            </tr>
            <tr>
                <td class="label">Score tekst:</td>
                <td v-html="scores.total_scores.score_text"></td>
            </tr>
            <tr>
                <td colspan="2">
                    <table class="result-table full-width">
                        <tr class="headline">
                            <td class="label">Pijler</td>
                            <td class="data-cell" v-for="(rolscore, index) in scores.role_scores">{{index+1}}</td>
                        </tr>
                        <tr>
                            <td class="label">Aantal vragen</td>
                            <td class="data-cell" v-for="rolscore in scores.role_scores">{{rolscore.count}}</td>
                        </tr>
                        <tr>
                            <td class="label">Gemiddelde teamscore</td>
                            <td class="data-cell" v-for="rolscore in scores.role_scores">{{rolscore.total}}</td>
                        </tr>
                        <tr>
                            <td class="label">Percentuele gemiddelde score</td>
                            <td class="data-cell" v-for="rolscore in scores.role_scores">{{rolscore.percentage}}%</td>
                        </tr>
                        <tr>
                            <td class="label">Beoordeling teamscore</td>
                            <td class="data-cell" v-for="rolscore in scores.role_scores">{{rolscore.score_class}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <barchart-component v-if="loaded" :chart-data="chartData"></barchart-component>
        <button @click="downloadTeamscores">Download teamscores</button>
        <button @click="downloadTeamreport">Download Teamrapport</button>
    </div>
</template>

<script>
export default {
    name: "Teamscores",

    data() {
        return {
            loaded: false,
            errors: [],
            team: {"name": "onbekend"},
            chartData: null,
            scores: {"total_scores": []},
            counter: 0
        }
    },

    props: ['baseUrl', 'moduleId', 'teamId'],

    mounted() {
        this.getScores();
    },

    methods: {
        downloadTeamscores() {
            let url = '/api/modules/'+this.moduleId+'/teams/' +this.teamId + '/scores/download';
            window.location = url;
        },
        downloadTeamreport() {
            let url = '/api/modules/'+this.moduleId+'/teams/' +this.teamId + '/report';
            window.location = url;
        },
         getScores() {
            axios.get('/api/modules/' + this.moduleId + '/teams/' + this.teamId + '/scores')
                .then(response => {
                    if (response.data.result == 'success') {
                        this.scores = response.data.data;
                        this.team = response.data.team;
                        this.chartData = response.data.chartData;
                        this.loaded = true;
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response.status == 401) {
                        location.href = '';
                    } else {
                        alert(error);
                    }
                });
        }
    }
}
</script>

<style scoped>
    .score-table{
        margin-bottom: 20px;
    }
    .score-table>tr>td.label{
        width: 200px;
    }
    table.full-width{
        width: 100%;
    }
    tr.headline td{
        font-weight: bold;
        border-bottom: 1px solid darkgray;
    }
    td.data-cell {
        text-align: center;
    }
    .result-table td.label {
        border-right: 1px solid darkgray;
    }
    td{
        text-align: left;
    }
</style>
