<template>
    <div class="edit-mode-component">
        <input name="edit-mode" type="checkbox" :checked="checked" @click="toggleEditMode()"/>&nbsp;Wijzig modus
    </div>
</template>

<script>
export default {
    name: "edit-mode",
    data() {
        return {editMode: false};
    },
    props: ['checked'],
    methods:{
        toggleEditMode() {
            this.$emit('editModeChanged', !this.checked);
        }
    }
}
</script>

<style scoped>
    .edit-mode-component{
        position: absolute;
        right: 30px;
        top: 5px;
    }
</style>
