<template>
    <div class="card-contents">
        <h2>Teams beheer</h2>

        <div v-for="module in teams" class="team-block">
            <h3>{{module.name}}</h3>
            <table class="table">
                <thead>
                    <th>Id</th>
                    <th>Naam</th>
                    <th># Scores</th>
                    <th>Url</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="team in module.teams" :key="team.id">
                        <td>{{team.id}}</td>
                        <td>{{team.name}}</td>
                        <td>{{team.userscores_count}}</td>
                        <td>{{ baseUrl+'/start/'+module.name+'/'+module.variant+'/'+module.version }}?team={{ team.id }}</td>
                        <td><a v-if="team.userscores_count > 0" :href="baseUrl+'/dashboard/modules/'+module.id+'/teams/'+team.id+'/scores'">Scores</a></td>
                    </tr>
                </tbody>
            </table>
         </div>
        <div class="new-team-div">
            <select v-model="newTeamModuleId">
                <option name="select_module" v-for="module in teams" :value="module.id">{{module.name}}</option>
            </select>
            <input type="text" name="new_team_name" placeholder="Naam nieuw team" v-model="newTeamName"/>
            <button @click="newteam()">+&nbsp;Toevoegen</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Teams",
        data() {
            return {
                teams: [],
                errors: [],
                newTeamModuleId: null,
                newTeamName: ''
            }
        },

        mounted() {
            this.getTeams();
        },

        props: ['baseUrl'],

        methods: {
            newteam() {
                if (this.newTeamName.trim() == '') {
                    alert('Vul eerst een teamnaam in');
                } else {
                    if(this.newTeamModuleId === null) {
                        alert('Kies eerst een module');
                    } else {
                        this.storeNewTeam();
                    }
                }
            },
            storeNewTeam() {
                let data = {"name": this.newTeamName, "module_id": this.newTeamModuleId};
                axios.post('/api/teams', data)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.teams = response.data.data;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 422){
                            alert(error.response.data.message);
                        } else {
                            alert('Er is een fout opgetreden bij het opslaan.');
                        }
                    });
            },
            getTeams() {
                axios.get('/api/teams')
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.teams = response.data.data;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            }
        }
    }
</script>

<style scoped>
    .selectable:hover td{
        background-color: #7f9b90;
        color: white;
    }
    .team-block{
        margin-top: 20px;
    }
    .new-team-div{
        display: flex;
        gap: 5px;
        border-top: 1px solid black;
        padding-top: 10px;
    }
</style>
