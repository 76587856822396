<template>
    <div class="card-contents">
        <h2>Module <span v-if="module">{{module.name}} {{module.variant}} {{module.version}}</span></h2>
        <a v-if="canEdit" :href=this.downloadLink>Download kandidaten</a><br/>
        <table class="table" v-if="module">
            <tbody>
                <tr>
                    <td>Id</td>
                    <td>{{module.id}}</td>
                </tr><tr>
                    <td>Naam</td>
                    <td>{{module.name}}</td>
                </tr><tr>
                    <td>Alias</td>
                    <td>{{module.alias}}</td>
                </tr><tr>
                    <td>Variant</td>
                    <td>{{module.variant}}</td>
                </tr><tr>
                    <td>Versie</td>
                    <td>
                        {{module.version}}
                        <span v-if="module.version_info">: {{module.version_info}}</span>
                    </td>
                </tr><tr>
                    <td>Feedback</td>
                    <td>
                        <select v-model="module.feedback" :disabled="!editMode">
                            <option value="0">Uit</option>
                            <option value="1">Aan</option>
                        </select>
                    </td>
                </tr><tr>
                    <td>Start</td>
                    <td>{{module.pivot.active_from}}</td>
                </tr><tr>
                    <td>Score-teksten</td>
                    <td>
                        <a :href="baseUrl+'/dashboard/storeGroup/' + module.score_group">{{module.score_group}}</a>
                    </td>
                </tr><tr>
                    <td>Doelgroep</td>
                    <td>{{module.target_group}}</td>
                </tr><tr>
                    <td>Competenties / pagina</td>
                    <td>{{module.items_per_page}}</td>
                </tr><tr>
                    <td>Scores in scan</td>
                    <td>
                        <ol>
                            <li v-for="answer in module.answers">{{answer.answer}} (score: {{answer.score}})</li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td>Doelgroep</td>
                    <td>
                        <div v-if="!editMode" v-html="module.target_group"></div>
                        <input type="text"
                            v-model="module.target_group"
                            v-if="editMode" />
                    </td>
                </tr>
                <tr>
                    <td>Omschrijving</td>
                    <td>
                        <div v-if="!editMode" v-html="module.description"></div>
                        <editor
                            model-events="change"
                            v-if="editMode"
                            v-model="module.description"
                            apiKey="5d7w0jpgc9mj763ravnroktqmtymlyc2jw5tlnxsu8xkznoi"
                            :init="tinyInit"
                            @change="handleDescriptionChange"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Instructies</td>
                    <td>
                        <div v-if="!editMode" v-html="module.instructions"></div>
                        <editor
                            model-events="change"
                            v-if="editMode"
                            v-model="module.instructions"
                            apiKey="5d7w0jpgc9mj763ravnroktqmtymlyc2jw5tlnxsu8xkznoi"
                            :init="tinyInit"
                            @change="handleInstructionsChange"
                        />
                    </td>
                </tr>
               <tr>
                    <td>Statistiek</td>
                    <td>
                        <stats-component
                            :base-url="baseUrl"
                            :module-id="moduleId"
                        ></stats-component>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="buttons-right">
            <button v-if="editMode" type="button" @click="cancelChanges">Annuleren</button>
            <button v-if="editMode" type="button" @click="saveChanges">Opslaan</button>
        </div>
        <edit-mode v-if="canEdit" :checked="editMode" @editModeChanged="changeEditMode"></edit-mode>
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
        name: "Modules",
        components: {
            Editor
        },
        data() {
            return {
                module: null,
                errors: [],
                editMode: false,
                canEdit: false,
                changed: false,
                tinyInit: {
                    plugins: [
                        'lists link paste'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic | bullist numlist outdent indent | link'
                }
            }
        },

        mounted() {
            this.getModule();
        },

        props: ['baseUrl', 'moduleId'],
        computed: {
            downloadLink: function () {return '/modules/'+this.module.id+'/emails';}
        },
        methods: {
            testReport: function() {
                let url= '/api/module/'+this.moduleId+'/testReport';
                window.open(url);
            },

            handleInstructionsChange: function(changedText) {
                this.module.instructions = changedText;
            },

            handleDescriptionChange: function(changedText) {
                this.module.description = changedText;
            },

            changeEditMode: function (newEditMode) {
                this.editMode = newEditMode;
            },
            getModule() {
                axios.get('/api/module/'+this.moduleId)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.module = response.data.data;
                            this.canEdit = response.data.canEdit;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            cancelChanges() {
                this.editMode=false;
                this.changed=false;
                this.getModule();
            },
            saveChanges() {
                document.activeElement.blur();
                axios.patch('/api/module/'+this.moduleId, this.module)
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.module = response.data.data;
                            this.canEdit = response.data.canEdit;
                            this.editMode=false;
                            this.changed=false;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
           }
        }
    }
</script>

<style scoped>
</style>
