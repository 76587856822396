// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../node_modules/bootstrap-icons/icons/check-square.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../../node_modules/bootstrap-icons/icons/x-square-fill.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkicon[data-v-7b9c7403]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.delete-icon[data-v-7b9c7403]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", "",{"version":3,"sources":["webpack://./resources/js/components/TextEdit.vue"],"names":[],"mappings":"AAuCA,4BACA,wDACA,CACA,8BACA,wDACA","sourcesContent":["<template>\n    <div class=\"text-edit-component\">\n        <input name=\"edit-text\" type=\"text\" v-model=\"editText\" ref=\"input_element\"/>\n        <span class=\"righttop-icon checkicon\" @click=\"saveChanges\"></span>\n        <span class=\"righttop-icon delete-icon\" @click=\"cancelChanges\"></span>\n    </div>\n</template>\n\n<script>\nexport default {\n    name: \"text-edit\",\n    props: ['text'],\n    data() {\n        return {editText: ''};\n    },\n    mounted() {\n        this.editText = this.text;\n        this.$refs.input_element.focus();\n    },\n    methods: {\n        saveChanges: function() {\n            this.$emit('valueChanged', this.$refs.input_element.value);\n        },\n        cancelChanges: function() {\n            this.$emit('editCancelled');\n        }\n    }\n}\n</script>\n\n<style scoped>\n    .checkicon{\n        background-image: url('~bootstrap-icons/icons/check-square.svg');\n    }\n    .delete-icon{\n        background-image: url('~bootstrap-icons/icons/x-square-fill.svg');\n    }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
