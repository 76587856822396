<template>
    <div class="dropdown-edit-component">
        <editor
            v-model="conceptText"
            apiKey="5d7w0jpgc9mj763ravnroktqmtymlyc2jw5tlnxsu8xkznoi"
            :init="tinyInit"
            ref="input_element"
            @keyup="handleKeyUp"
        />
        <div>
            <span class="righttop-icon checkicon" @click="saveChanges"></span>
            <span class="righttop-icon delete-icon" @click="cancelChanges"></span>
        </div>
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
    name: "dropdown-edit",
    components: {
        Editor
    },
    props: ['text', 'recordId', 'valueChanged'],
    data() {
        return {
            originalText: '',
            conceptText: '',
            tinyInit: {
                plugins: [
                    'lists link paste'
                ],
                toolbar: 'undo redo | formatselect | bold italic | bullist numlist outdent indent | link'
            }
        };
    },
    mounted() {
        this.conceptText = this.text;
        this.originalText = this.text;
    },
    computed: { },
    methods: {
        handleKeyUp(){
          this.valueChanged = true;
        },
        saveChanges: function() {
            this.$emit('textChanged', this.$refs.input_element.value, this.recordId);
        },
        cancelChanges: function() {
            this.conceptText = this.originalText;
            this.valueChanged = false;
        }
    }
}
</script>

<style scoped>
    .checkicon{
        background-image: url('~bootstrap-icons/icons/check-square.svg');
    }
    .delete-icon{
        background-image: url('~bootstrap-icons/icons/x-square-fill.svg');
    }
    textarea{
        width: 100%;
        height: 200px;
    }
</style>
